import type { CommonProperties, CoreAnalyticsOptions } from 'owa-analytics-types';
import { getSessionId } from 'owa-config/lib/getSessionId';
import { getHostValue } from 'owa-config/lib/getHostValue';
import { getNativeHostVersion, getEnhancedHostDeviceId } from 'owa-config/lib/enhancedHost';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'getPhysicalRing' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible.
 *	> 'getVariantEnvironment' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { getPhysicalRing, getVariantEnvironment } from 'owa-metatags';
import getUserType from 'owa-service/lib/getUserType';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { getThreadName } from 'owa-thread-config';
import { getCachedSettings } from './getCachedSettings';
import { trace } from 'owa-trace';

let commonPropertiesCache: CommonProperties | undefined;

export async function getCommonProperties(analyticsOptions: CoreAnalyticsOptions) {
    if (commonPropertiesCache) {
        return commonPropertiesCache;
    }

    const { sessionInfo, accountScopeSessionSettings, opxSessionInfo, userConfiguration } =
        getCachedSettings() ?? {};

    const sessionSettings = userConfiguration?.SessionSettings;

    const commonProperties: CommonProperties = {
        BEServer: sessionInfo?.backend,
        MBXGuid: accountScopeSessionSettings?.SessionSettings?.MailboxGuid,
        Forest: sessionInfo?.forest,
        Puid: sessionSettings?.UserPuid,
        ClientId: sessionInfo?.clientId,
        Ring: sessionInfo?.logicalRing,
        UserType: getUserType(accountScopeSessionSettings, !!sessionInfo?.isEdu),
        Layout: sessionInfo?.layout,
        TenantGuid: sessionSettings?.ExternalDirectoryTenantGuid,
        'Session.Id': getSessionId(),
        PhysicalRing: getPhysicalRing(),
        VariantEnv: getVariantEnvironment(),
        Host: getHostValue(),
        HostVersion: getNativeHostVersion(),
        Thread: getThreadName(),
        DeviceId: getEnhancedHostDeviceId(),
    };

    if (opxSessionInfo) {
        commonProperties['HostedScenario'] = opxSessionInfo.hostedScenario;
    }

    if (!!analyticsOptions?.shouldIncludeUserInfoId) {
        commonProperties['UserInfo.Id'] = sessionSettings?.UserPuid;
        commonProperties['UserInfo.IdType'] =
            accountScopeSessionSettings &&
            accountScopeSessionSettings?.SessionSettings?.WebSessionType !== (0).toString()
                ? 'MSAPUID'
                : 'OrgIdPuid';
    }

    commonPropertiesCache = commonProperties;

    return commonPropertiesCache;
}

export function updateCommonPropertiesCache(commonProperties: CommonProperties) {
    trace.info('Updating common properties cache', 'analytics');
    commonPropertiesCache = commonProperties;
}
