import type { UnifiedEventsName } from 'owa-analytics-types';

const legacyMappings: {
    [key: string]: UnifiedEventsName;
} = {
    TnS_DeleteItem: 'DeleteMail', // ✔️ Added and working
    TnS_DeleteConversation: 'DeleteMail', // ✔️ Added and working
    TnS_CreateFolder: 'CreateFolder', // ✔️ Added and working
    FlagUnflagMail: 'FlagUnflagMail', // ✔️ Added and working
    TnS_MarkConversationRead: 'MarkReadUnreadMail', // ✔️ Added and working
    TnS_MarkConversationUnread: 'MarkReadUnreadMail', // ✔️ Added and working
    TnS_MarkItemRead: 'MarkReadUnreadMail', // ✔️ Added and working
    TnS_MarkItemUnread: 'MarkReadUnreadMail', // ✔️ Added and working
    SelectMailItem: 'ViewMessage', // ✔️ Added and working
    SelectMailItemNonCritical: 'ViewMessage', // ✔️ Added and working
    TnS_Move: 'MoveMessage', // ✔️ Added and working
    MailComposeNew: 'ComposeMessage', // ✔️ Added and working
    // UT_TODO: Find legacy event that related to "EditDraft" event
    ComposeMessage: 'ComposeMessage', // ❌ Couldn't find the "EditDraft" operation
    GroupMailComposeSend: 'SendMessage', // ❌ Need to add all the sub actions
    MailComposeSend: 'SendMessage', // ❌ Need to add all the sub actions
    TnS_SelectFocusedPivot: 'SwitchFocusedOther', // ✔️ Added and working
    TnS_SelectOtherPivot: 'SwitchFocusedOther', // ✔️ Added and working
    SwitchMailFolder: 'SwitchMailFolder', // ✔️ Added and working
    AttachmentCreation: 'AddAttachment', // ❌ Need to add all the sub actions (same for all attachement events)
    AttachmentDownload: 'DownloadAttachment', // ❌ Need to add all the sub actions (same for all attachement events)
    PreviewAttachmentInSxSE2E: 'ViewAttachment', // ❌ Need to add all the sub actions (same for all attachement events)
    SaveContact: 'SaveContact', // ⚠️ Added
    DeleteContact: 'DeleteContact', // ⚠️ Added
    LPCRenderedFromHoverTarget: 'ViewContact', // ⚠️ Added
    LPCRenderedFromOnOpenLPC: 'ViewContact', // ⚠️ Added
    ViewContact: 'ViewContact', // ⚠️ Added
    ViewListOfAccountContacts: 'ViewListOfAccountContacts', // ⚠️ Added
    Search_SuggestionsE2E: 'PerformSearch', // ✔️ Added and working
    SaveCalendarEvent: 'SaveCalendarEvent', // ❌ Couldn't find the attendee count
    TBD18: 'DeleteCalendarEvent', // ❌ Missing AttendeeCount and SubAction mapping
    LoadCalendarModuleE2E: 'LoadCalendar', // ✔️ Added and working
    SwitchCalendarView: 'SwitchCalendarView', // ✔️ Added and working
    ViewCalendarEvent: 'ViewCalendarEvent', // ❌ Couldn't find attendee count
    GenericAction: 'GenericAction', // ✔️ Added and working
    PlacesWorkspacesCreateReservation: 'PlacesBookReservation', // ✔️ Added and working
    PlacesWorkspacesBooking: 'PlacesBookReservation', // ✔️ Added and working
    PlacesOpenPlacesFinder: 'PlacesOpenPlacesFinder', // ⚠️ Added
    PlacesViewWorkPlan: 'PlacesViewWorkPlan', // ✔️ Added and working
    PlacesConfirmWorkPlan: 'PlacesConfirmWorkPlan', // ✔️ Added and working
    PlacesSetWorkPlan: 'PlacesSetWorkPlan', // ✔️ Added and working
    PlacesSetWorkRoutine: 'PlacesSetWorkRoutine', // ⚠️ Added
    PlacesViewConnections: 'PlacesViewConnections', // ⚠️ Added
    PlacesConnectionsEdited: 'PlacesConnectionsEdited', // ⚠️ Added
    PlacesViewAdminPortal: 'PlacesViewAdminPortal', // ✔️ Added and working,
    PlacesAppLoad: 'PlacesAppLoad', // ✔️ Added and working,
    PlacesDashboardLoadE2E: 'PlacesDashboardLoadE2E', // ✔️ Added and working
    PlacesHomepageV3LoadE2E: 'PlacesBootExplorePageE2E', // ✔️ Added and working
    PlacesWelcomeShown: 'PlacesWelcomeShown', // ✔️ Added and working,
    PlacesWelcomeLocationSelected: 'PlacesWelcomeLocationSelected', // ✔️ Added and working,
    PlacesOnboardingProgressClicked: 'PlacesOnboardingProgressClicked', // ✔️ Added and working,
    PlacesWeeklyWorkRoutineShown: 'PlacesWeeklyWorkRoutineShown', // ✔️ Added and working,
    PlacesAdminAction: 'PlacesAdminAction', // ⚠️ Added
    PlacesNavigationClick: 'PlacesNavigationClick', // ✔️ Added and working
    PlacesSetDayForAgendaView: 'PlacesSetDayForAgendaView',
    PlacesComponentViewChange: 'PlacesComponentViewChange',
    PlacesShareWorkPlan: 'PlacesShareWorkPlan',
    PlacesMapsLoadE2E: 'PlacesMapsLoadE2E', // ⚠️ Added
    OpenWorkPlanPeek: 'OpenWorkPlanPeek',
    OpenWorkPlansOnboardingPeek: 'OpenWorkPlansOnboardingPeek',
    OpenWorkPlansOnboardingSetup: 'OpenWorkPlansOnboardingSetup',
    OpenWorkPlansOnboardingReview: 'OpenWorkPlansOnboardingReview',
    OpenWorkPlanExpandedView: 'OpenWorkPlanExpandedView',
    PlaceWorkplaceCopilotFeedback: 'PlaceWorkplaceCopilotFeedback',
    WorkPlanPeekSuggestionQueued: 'WorkPlanPeekSuggestionQueued',
    WorkPlanPeekSuggestionViewed: 'WorkPlanPeekSuggestionViewed',
    WorkPlanPeekCardUserAction: 'WorkPlanPeekCardUserAction',
    PlacesSetWorkPlanOOF: 'PlacesSetWorkPlanOOF',
    OpenWorkPlansOnboardingSetPlan: 'OpenWorkPlansOnboardingSetPlan',
    QuickBookModalAction: 'QuickBookModalAction',
    QuickBookLoadedInPeek: 'QuickBookLoadedInPeek',
    DeskBookLoadedInPeek: 'DeskBookLoadedInPeek',
    MapInteraction: 'MapInteraction',
    FETCH_PLACES_SETTINGS: 'PlacesUserSettings',
    PlacesWorkGroupManagement: 'PlacesWorkGroupManagement',
    PlacesWorkGuidanceManagement: 'PlacesWorkGuidanceManagement',
    PlacesWorkGuidanceInteraction: 'PlacesWorkGuidanceInteraction',
    PlacesNoWorkDaysStateAction: 'PlacesNoWorkDaysStateAction',
    PlacesTriggerWorkPlanOnboarding: 'PlacesTriggerWorkPlanOnboarding',
    PlacesExploreCollaboratorsView: 'PlacesExploreCollaboratorsView',
    PlacesPageLoad: 'PlacesPageLoad',
    TodaysOthersInOfficeLoaded: 'TodaysOthersInOfficeLoaded',
    OpenTodaysOthersInOfficePeek: 'OpenTodaysOthersInOfficePeek',
    TodaysOthersInOfficePeekDateNavigation: 'TodaysOthersInOfficePeekDateNavigation',
    PlacesExploreCardInteraction: 'PlacesExploreCardInteraction',
    PlacesPeopleInteraction: 'PlacesPeopleInteraction',
    PlacesWorkGroupsInteraction: 'PlacesWorkGroupsInteraction',
    PlacesPeopleSidebarInteraction: 'PlacesPeopleSidebarInteraction',
    PlacesCollaboratorsAddButton: 'PlacesAddCollaboratorE2E',
    PlacesCollaboratorsSearchButton: 'PlacesAddCollaboratorE2E',
    PlacesCollaboratorsDelete: 'PlacesDeleteCollaboratorE2E',
    GetPlaceSuggestionsUserLatency: 'PlacesFinderLoadE2E',
    PlacesTimestripSelectDate: 'PlacesTimestripSelectDate',
    PlacesAgendaPanelLoadE2E: 'PlacesAgendaPanelLoadE2E',
    PlacesPageLoaderE2E: 'PlacesPageLoaderE2E',
    PlacesUserHasInvalidTimeZone: 'PlacesUserHasInvalidTimeZone',
    HybridGuidanceLoadWorkGroups: 'HybridGuidanceLoadWorkGroups',
    HybridGuidanceCreateGuidance: 'HybridGuidanceCreateGuidance',
    PlacesFinderFirstInteraction: 'PlacesFinderFirstInteraction',
    PlacesClosePlacesFinder: 'PlacesClosePlacesFinder',
    HybridGuidanceUpdateGuidance: 'HybridGuidanceUpdateGuidance',
    PlacesBuildingNavigation: 'PlacesBuildingNavigation',
    PlacesGlobalActionButton: 'PlacesGlobalActionButton',
    HybridGuidanceViewWorkGroup: 'HybridGuidanceViewWorkGroup',
    QuickBookSuggestionsLoadE2E: 'QuickBookSuggestionsLoadE2E',
    QuickBookRoomRequestE2E: 'QuickBookRoomRequestE2E',
    QuickBookModalInteractionTime: 'QuickBookModalInteractionTime',
    QuickBookSuggestionInteraction: 'QuickBookSuggestionInteraction',
    PlacesCheckIntoBuildingE2E: 'PlacesCheckIntoBuildingE2E',
    PlacesHybridGuidanceLoadE2E: 'HybridGuidancePageLoadE2E',
    RoomFinderSelectPlace: 'RoomFinderSelectPlace',
    PlacesGuidanceCardsLoad: 'PlacesGuidanceCardsLoad',
    CalendarCopilotUserAction: 'CalendarCopilotUserAction',
    WorkPlanPeekSuggestionBackgroundEvent: 'WorkPlanPeekSuggestionBackgroundEvent',
    CalendarFeatureLatency: 'CalendarFeatureLatency',
    OpenDeskReservationForm: 'OpenDeskReservationForm',
    LoadDeskSuggestions: 'LoadDeskSuggestions',
    SaveDeskBookingReservation: 'SaveDeskBookingReservation',
    CancelDeskBookingReservation: 'CancelDeskBookingReservation',
};

export function matchEventDefinition(eventName: string): UnifiedEventsName | string | undefined {
    return legacyMappings[eventName];
}
