import type { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-js';

export interface DiagnosticOverrides {
    EndpointUrlChanged?: boolean;
    UseUnifiedSchemaChanged?: boolean;
    SkipCommonPropertiesChanged?: boolean;
    UpdateCachesFromMainThreadIsRegistered?: boolean;
    MsaDeviceTicketChanged?: boolean;
    Offline?: boolean;
    InitinalizingTenant?: string;
    CalledAddOrUpdateCommonPropertiesFromInitialization?: boolean;
    CalledAddOrUpdateCommonPropertiesFromLogDatapoint?: boolean;
    UpdatedCachedCommonPropertiesFromMainThread?: string;
    FailedToUpdateCahchedCommonPropertiesFromMainThread?: boolean;
}

export function addCommonDiagnosticProperties(
    endpointUrl?: string,
    item?: IExtendedTelemetryItem,
    diagnosticOverrides?: DiagnosticOverrides
) {
    if (item?.data) {
        let diagnostics = {};
        const originalDiagnostics = item.data['diagnostics'];

        if (originalDiagnostics) {
            // We can get strings that are not JSON, like callstacks
            try {
                diagnostics = JSON.parse(originalDiagnostics as string);
            } catch (e) {
                diagnostics = {
                    originalDiagnostics,
                };
            }
        }

        item.data['diagnostics'] = JSON.stringify({
            ...diagnostics,
            ...diagnosticOverrides,
            EndpointUrl: endpointUrl || 'none',
        });
    }
}
